import React from 'react'
import SbEditable from 'storyblok-react'
import usePageProps from '../../hooks/usePageProps'
import useStoryblokBridge from '../../hooks/useStoryblokBridge'
import TourPage from './TourPage'

const TourPageWithState = () => {
    const { content } = useStoryblokBridge()
    const { supplierContent } = usePageProps()

    return (
        <SbEditable content={content}>
            <TourPage content={content} supplierContent={supplierContent} />
        </SbEditable>
    )
}

export default TourPageWithState
